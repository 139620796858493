import React, { useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import './nosotros.css';
import { titular } from '../../json/titular';
import { Link } from 'react-router-dom';
import { carouselInicio } from '../../json/carousel';



const Inicio = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <div className='container'>
                <div className='row'>
                    <div className='col-12'>

                    </div>

                    <div className='secretaria_funciones'>
                        
                        <h3 className='title'>MISIÓN</h3>
                        <hr className='hr-gob' />
                        <p>Satisfacer las necesidades de movilidad de los habitantes del Estado,implementando y operando soluciones de Transportación Masiva, con las cualeslas personas usuarias 
                            disfrutan de la accesibilidad total y la integración plena de los servicios de transporte; al tiempo que se benefician por la innovación tecnológica y el 
                            cambio estructural, con base en la eficacia y el uso racional de los modos de transporte.</p>
                        <h3 className='title'>VISIÓN</h3>
                        <hr className='hr-gob' />
                        <p>Ofrecer a la población usuaria del Servicio Público de Transporte Masivo de Pasajeros, una gama de servicios de transportación integrados, flexibles y dinámicos, reduciendo
                           los tiempos de su desplazamiento y mitigando los costos de transportación; diseñando y operando para su comodidad redes de transporte público, de calidad homogénea y justo
                           precio, con las cuales se equilibra el modelo de desarrollo urbano y el modelo de movilidad.</p>
                        <h3 className='title'>OBJETIVOS</h3>
                        <hr className='hr-gob' />
                        <p>Planear, determinar y satisfacer las necesidades de la población en materia del Servicio Público de Transporte Masivo de Pasajeros, del Servicio de Pago Electrónico, de la operación del Centro General de Gestión de Operaciones, y de las tarifas que a cada uno de ellos resulte aplicable en el Estado, de los Servicios Auxiliares y Conexos relacionados con ello y de las autorizaciones y convenios de cualquier tipo que sean relativos a la materia, sirviéndose de los elementos técnicos y avances tecnológicos disponibles; ordenando la incorporación, uso y admisión de nuevas tecnologías, a fin de elevar la calidad del servicio y la eficiencia en su operación y control.</p>
                        <h3 className='title'>PROGRAMAS Y PROYECTOS</h3>
                        <hr className='hr-gob' />
                        <p>Para dar solución al contexto actual de la movilidad, el Gobierno del Estado presenta el proyecto Sistema Integrado de Transporte Masivo de la Zona Metropolitana de Pachuca (Tuzobús) el cual beneficiará a 381 mil 540 usuarios del transporte público en 10 municipios de la zona metropolitana de Pachuca.

El sistema considera el desarrollo de seis troncales.

Tuzobús es un Sistema de Autobús de Tránsito Rápido (Bus Rapid Transit, BRT por sus siglas en inglés):
Más rápido, más seguro, más confiable con el cual se reducirán las emisiones de gases contaminantes y tiempo de traslado.

Más información en: http://www.tuzobus.gob.mx/</p>



                        <div className='btn_organigrama'>
                            <Link to="/Transparencia/Rubros/DetalleRubro/Estructura">Organigrama</Link>
                            <Link to="/Transparencia/Rubros/DetalleRubro/Normatividad">Normatividad</Link>
                        </div>
                        <div className='secretaria-redes'>
                            <a href=''><i class="fa-brands fa-facebook-f"></i></a>
                            <a href=''><i class="fa-brands fa-instagram"></i></a>
                            <a href=''><i class="fa-brands fa-twitter"></i></a>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default Inicio