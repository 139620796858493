import React, { useState } from 'react';

const Servicios = () => {
    const [contador, setContador] = useState(0);
    const suma = () => {
        setContador(contador + 1)
    }


  return (
    <div className='container container-mh'>
        <div className='row'>
            <div className='col-sm-6'>
                <h1>{suma}</h1>
            </div>
            <div className='col-sm-6'>
                
            </div>
        </div>
    </div>
  )
}

export default Servicios