export const ultimasNoticias = [
    /*{
        "imagen" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/img/Banner_TC_2023.jpeg",
        "url" : "http://transparenciadocs.hidalgo.gob.mx/dependencias/contraloriat/dir1/Documentos/Convocatoria_2023.pdf",
        "titulo" : "XVIII edición Concurso Nacional de Transparencia en Corto",
        "fecha" : "02 de abril del 2023"
    },
    {
        "imagen" : "http://sitmah.hidalgo.gob.mx/img/codigo-conducta-2023.jpg",
        "url" : "http://sitmah.hidalgo.gob.mx/img/codigo-conducta-2023.jpg",
        "titulo" : "Código de Conducta Nacional",
        "fecha" : "02 de abril del 2023"
    },
    {
        "imagen" : "https://cdn.hidalgo.gob.mx/transicion/feria.png",
        "url" : "www.gooogle.com",
        "titulo" : "Aqui va el titulo 3",
        "fecha" : "02 de marzo del 2023"
    }*/
]