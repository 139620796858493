export const carouselInicio = [
    {
        "imagen" : "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png"
    },
    {
        "imagen" : "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png"
    },
    {
        "imagen" : "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png"
    }
]