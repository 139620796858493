import { situacionFinanciera, estadoActividades, estadoHP, estadoSF, notasEF, estadoAA, estadoFE, estadoADOP, estado_2_1, estado_2_2, estado_2_3, estado_2_4, estado_2_5, endeuda_2_6, interes_2_7, indicador_2_8, gasto_3_1, programas_3_2, indicadores_3_3, evaluacion_3_4, metodo_3_5, programas_3_6, muebles_4_1, inmuebles_4_2, cuenta_p_5_1, dictamen_5_2, aseh_5_3, trimes_6_1, anual_6_2, pae_7_1, resultado_7_2, montos_7_3, programas_8, n_9_1, n_9_2, n_9_3, n_9_4, n_9_5, n_9_6, n_9_7, n_9_8, n_9_9, n_9_10, n_9_11, n_9_12, n_9_13, n_9_14, n_9_15, n_9_16, n_9_17, n_9_18, n_9_19, n_9_20, n_9_21, n_9_22, n_9_23, n_9_24, INICIATIVA_DE_LEY_INGRESOS, INFORMACION_COMPLEMENTARIA, LEY_INGRESOS, LEY_ENTIDADES, LEY_CONTABILIDAD, MANUAL_CONTABILIDAD, NORMA_1, NORMA_2, NORMA_3, NORMA_4, NORMA_5, NORMA_6, NORMA_7, NORMA_8, NORMA_9, NORMA_10, NORMA_11, NORMA_12, NORMA_13, NORMA_14, NORMA_15, NORMA_16, NORMA_17, NORMA_18, NORMA_19, NORMA_20, NORMA_21, NORMA_22, wee_23, NORMA_24, NORMA_25, NORMA_26, NORMA_27, NORMA_28, NORMA_29, NORMA_30, NORMA_31, NORMA_32, informePC, conciliacionPC, bueno} from "../json/anioArmonizacion";
import { disFinanciera } from "../json/anioDisciplina";


export const obtenerDatosSeleccionados = (selectedItemIndex) => {
  let datosSeleccionados = [];

  switch (selectedItemIndex) {

    case '1.1 Estado de Situación Financiera':
      datosSeleccionados = situacionFinanciera;
      break;
    case '1.2 Estado de Actividades':
      datosSeleccionados = estadoActividades;
      break;
    case '1.3 Estado de Variación en la Hacienda Pública':
      datosSeleccionados = estadoHP;
      break;
    case '1.4 Estado de Cambios en la Situación Financiera':
      datosSeleccionados = estadoSF;
      break;
    case '1.5 Notas a los Estados Financieros':
      datosSeleccionados = notasEF;
      break;
    case '1.6 Estado Analitico del Activo':
      datosSeleccionados = estadoAA;
      break;
    case '1.7 Estado de Flujos de Efectivo':
      datosSeleccionados = estadoFE;
      break;
    case '1.8 Estado Analítico de la Deuda y Otros Pasivos':
      datosSeleccionados = estadoADOP;
      break;
    case '1.9 Informe de Pasivos Contingentes':
      datosSeleccionados = informePC;
      break;
    case '1.10 Conciliación Entre los Ingresos Presupuestarios y Contables':
      datosSeleccionados = conciliacionPC;
      break;
    case '2.1 Estado Analítico de Ingresos':
      datosSeleccionados = estado_2_1;
      break;
    case '2.2 Estado Analítico del Ejercicio del Presupuesto de Egresos C.O.G':
      datosSeleccionados = estado_2_2;
      break;
    case '2.3 Estado Analítico del Ejercicio del Presupuesto de Egresos C.E':
      datosSeleccionados = estado_2_3;
      break;
    case '2.4 Estado Analítico del Ejercicio del Presupuesto de Egresos C.F':
      datosSeleccionados = estado_2_4;
      break;
    case '2.5 Estado Analítico del Ejercicio del Presupuesto de Egresos C.A':
      datosSeleccionados = estado_2_5;
      break;
    case '2.6 Endeudamiento Neto':
      datosSeleccionados = endeuda_2_6;
      break;
    case '2.7 Interes de la Deuda':
      datosSeleccionados = interes_2_7;
      break;
    case '2.8 Indicadores de Postura Fiscal':
      datosSeleccionados = indicador_2_8;
      break;
    case '3.1 Gasto por Categoria Programática':
      datosSeleccionados = gasto_3_1;
      break;
    case '3.2 Programas y Proyectos de Inversión':
      datosSeleccionados = programas_3_2;
      break;
    case '3.3 Indicadores de Resultados':
      datosSeleccionados = indicadores_3_3;
      break;
    case '3.4 EVALUACIÓN DE DESEMPEÑO DE PROGRAMAS Y POLÍTICAS PUBlLICAS':
      datosSeleccionados = evaluacion_3_4;
      break;
    case '3.5 METODOLOGÍAS E INDICADORES DE DESEMPEÑO':
      datosSeleccionados = metodo_3_5;
      break;
    case '3.6 PROGRAMAS ANUAL DE EVALUACIONES':
      datosSeleccionados = programas_3_6;
      break;
    case '4.1 Muebles':
      datosSeleccionados = muebles_4_1;
      break;
    case '4.2 Inmuebles':
      datosSeleccionados = inmuebles_4_2;
      break;
    case '5.1 CUENTA PÚBLICA 2023':
      datosSeleccionados = cuenta_p_5_1;
      break;
    case '5.2 DICTAMEN AUDITORIA EXTERNA 2023':
      datosSeleccionados = dictamen_5_2;
      break;
    case '5.3 ASEH INFORMES GESTIÓN FINANCIERA':
      datosSeleccionados = aseh_5_3;
      break;
    case '6.1 Publicaciones Trimestrales':
      datosSeleccionados = trimes_6_1;
      break;
    case '6.2 Publicaciones Anuales':
      datosSeleccionados = anual_6_2;
      break;
    case '7.1 Programas Anual de Evaluaciónes (Metodología e Indicadores de Desempeño)':
      datosSeleccionados = pae_7_1;
      break;
    case '7.2 Resultado Del Programa Anual de Evaluaciones':
      datosSeleccionados = resultado_7_2;
      break;
    case '7.3 Montos Pagados por Ayudas y Subsidos':
      datosSeleccionados = montos_7_3;
      break;
    case '8.- PROGRAMAS ANUAL DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS':
      datosSeleccionados = programas_8;
      break;
    case '9.1 Estado de Situación Financiera Detallado':
      datosSeleccionados = n_9_1;
      break;
    case '9.2 Informe Analítico de la Deuda y Otros Pasivos':
      datosSeleccionados = n_9_2;
      break;
    case '9.3 Informe Analítico de Obligaciones Diferentes de Financiamientos':
      datosSeleccionados = n_9_3;
      break;
    case '9.4 Balance Presupuestario':
      datosSeleccionados = n_9_4;
      break;
    case '9.5 Estado Analítico de Ingresos Detallado':
      datosSeleccionados = n_9_5;
      break;
    case '9.6 Estado Analítico del Presupuesto de Egresos Detallado C.O.G':
      datosSeleccionados = n_9_6;
      break;
    case '9.7 Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado C.A':
      datosSeleccionados = n_9_7;
      break;
    case '9.8 Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado C.F':
      datosSeleccionados = n_9_8;
      break;
    case '9.9 Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado Clasificación de Servicios Personales por Categoria':
      datosSeleccionados = n_9_9;
      break;
    case '9.10 Balance Presupuestario de Recursos Diponibles Negativo 2017 Artículo 6 LDF':
      datosSeleccionados = n_9_10;
      break;
    case '9.11 Aumento o Creación del Presupuesto de Egresos Durante el Ejercicio de 2017 Artículo 8 LDF':
      datosSeleccionados = n_9_11;
      break;
    case '9.12 Devengados en el Año que Integra el Pasivo Circulante al Cierre del Ejercicio 2017 Artículo 13 LDF':
      datosSeleccionados = n_9_12;
      break;
    case '9.13 Ingresos Exedentes Derivados de Ingresos de Libre Diposición 2017 Artículo 14 LDF':
      datosSeleccionados = n_9_13;
      break;
    case '9.14 Contratación de Deuda Pública y Obligaciones a Corto Plazo Artículo 25 LDF':
      datosSeleccionados = n_9_14;
      break;
    case '9.15 Información Detallada de Contratación de Obligaciones a Corto Plazo Articulo 31 LDF':
      datosSeleccionados = n_9_15;
      break;
    case '9.16 Resultados de Ingresos LDF':
      datosSeleccionados = n_9_16;
      break;
    case '9.17 Resultados de Egresos LDF':
      datosSeleccionados = n_9_17;
      break;
    case '9.18 Proyecciones de Ingresos LDF':
      datosSeleccionados = n_9_18;
      break;
    case '9.19 Proyecciones de Egresos LDF':
      datosSeleccionados = n_9_19;
      break;
    case '9.20 Informe Sobre Resultados Actuariales LDF':
      datosSeleccionados = n_9_20;
      break;
    case '9.21 Guía de Cumplimiento LDF':
      datosSeleccionados = n_9_21;
      break;
    case 'DICTAMEN DE AUDITORIA EXTERNA 2017':
      datosSeleccionados = n_9_22;
      break;
    case 'CARTA DE OBSERVACIONES Y RECOMENDACIONES DE AUDITORIA EXTERNA':
      datosSeleccionados = n_9_23;
      break;
    case 'CUENTA PÚBLICA ANUAL':
      datosSeleccionados = n_9_24;
      break;
    case 'INICIATIVA DE LEY INGRESOS':
      datosSeleccionados = INICIATIVA_DE_LEY_INGRESOS;
      break;
    case 'LA INFORMACIÓN COMPLEMENTARIA PARA GENERAR LAS CUENTAS NACIONALES Y ATENDER OTROS REQUIRIMIENTOS PROVENIENTES DE ORGANISMOS INTERNACIONALES DE LOS MÉXICANOS ES MIEMBRO':
      datosSeleccionados = INFORMACION_COMPLEMENTARIA;
      break;
    case 'LEY DE INGRESOS':
      datosSeleccionados = LEY_INGRESOS;
      break;
    case 'LEY FEDERAL DE ENTIDADES PARAESTATALES':
      datosSeleccionados = LEY_ENTIDADES;
      break;
    case 'LEY GENERAL DE CONTABILIDAD GUBERNAMENTAL':
      datosSeleccionados = LEY_CONTABILIDAD;
      break;
    case 'MANUAL DE CONTABILIDAD':
      datosSeleccionados = MANUAL_CONTABILIDAD;
      break;
    case 'NORMA PARA ESTABLECER EL FORMATO PARA LA DIFUSIÓN DE LOS RESULTADOS DE LAS EVALUACIONES DE LOS RECURSOS FEDERALES MINISTRADOS A LAS ENTIDADES FEDERATIVAS':
      datosSeleccionados = NORMA_1;
      break;
    case 'NORMA PARA ESTABLECER LA ESTRUCTURA DE INFORMACIÓN DE MONTOS PAGADOS POR AYUDAS Y SUBSIDIOS':
      datosSeleccionados = NORMA_2;
      break;
    case 'NORMA PARA ESTABLECER LA ESTRUCTURA DE INFORMACIÓN DEL FORMATO DE PROGRAMAS CON RECURSOS FEDERALES POR ORDEN DE GOBIERNO':
      datosSeleccionados = NORMA_3;
      break;
    case 'NORMA PARA ESTABLECER LA ESTRUCTURA DEL CALENDARIO DE EGRESOS BASE MENSUAL':
      datosSeleccionados = NORMA_4;
      break;
    case 'NORMA PARA ESTABLECER LA ESTRUCTURA DEL CALENDARIO DE INGRESOS BASE MENSUALS':
      datosSeleccionados = NORMA_5;
      break;
    case 'NORMA PARA LA DIFUSIÓN A LA CIUDADANÍA DE LA LEY DE INGRESOS Y DEL PRESUPUESTO DE EGRESOS':
      datosSeleccionados = NORMA_6;
      break;
    case 'NORMAS PARA ESTABLECER LA ESTRUCTURA DE INFORMACIÓN DEL FORMATO DEL EJERCICIO Y DESTINO DE GASTO FEDERALIZADO Y REINTEGROS':
      datosSeleccionados = NORMA_7;
      break;
    case 'PAAAS':
      datosSeleccionados = NORMA_8;
      break;
    case 'PRESUPUESTO DE EGRESOS':
      datosSeleccionados = NORMA_9;
      break;
    case 'PRESUPUESTO DE INGRESOS':
      datosSeleccionados = NORMA_10;
      break;
    case 'PROYECTO DE PRESUPUESTO DE EGRESOS':
      datosSeleccionados = NORMA_11;
      break;
    case 'REMITIR A LA SHCP A TRAVÉS DEL SISTEMA DE INFORMACIÓN A QUE SE REFIERE EL ARTÍCULO 85 DE LA LFPRH LA INFORMACIÓN SOBRE EJERCICIO Y DESTINO DE GASTOS FEDERALES':
      datosSeleccionados = NORMA_12;
      break;
    case 'APLICACION DE RECURSOS FEDERALES PARA EL FONDO DE APORTACONES PARA LA INFRAESTRUCTURA SOCIAL FAIS':
      datosSeleccionados = NORMA_13;
      break;
    case 'APORTACIONES FEDERALES A FAETA':
      datosSeleccionados = NORMA_14;
      break;
    case 'APORTACIONES FEDERALES A FONDO DE AYUDA PARA SEGURIDAD PUBLICA':
      datosSeleccionados = NORMA_15;
      break;
    case 'APORTACIONES FEDERALES A FORTAMUN':
      datosSeleccionados = NORMA_16;
      break;
    case 'APORTACIONES FEDERALES EN MATERIA DE SALUD':
      datosSeleccionados = NORMA_17;
      break;
    case 'APORTACIONES FEDERALES A FONDO DE AYUDA PARA SEGURIDAD PUBLICA':
      datosSeleccionados = NORMA_18;
      break;
    case 'APROBACION DE LA LEY DE INGRESOS':
      datosSeleccionados = NORMA_19;
      break;
    case 'GASTO FEDERALIZADO Y REINTEGROS ANUAL':
      datosSeleccionados = NORMA_20;
      break;
    case 'GASTO FEDERALIZADO Y REINTEGROS TRIMESTRAL':
      datosSeleccionados = NORMA_21;
      break;
    case 'INICIATIVA_DE_LEY_DE_INGRESOS':
      datosSeleccionados = NORMA_22;
      break;
    case 'LEY.':
      datosSeleccionados = bueno;
      break;
    case 'NORMA PARA ESTABLECER EL FORMATO PARA LA DIFUSION DE LOS RESULTADOS DE LAS EVALUACIONES DE LOS RECURSOS FEDERALES MINISTRADOS A LAS ENTIDADES FEDERATIVAS':
      datosSeleccionados = NORMA_24;
      break;
    case 'NORMA PARA ESTABLECER LA ESTRUCTURA DEL CALENDARIO DE INGRESOS BASE MENSUAL':
      datosSeleccionados = NORMA_25;
      break;
    case 'NORMA PARA LA DIFUSION A LA CIUDADANIA DE LA LEY DE INGRESOS Y DEL PRESUPUESTO DE EGRESOS':
      datosSeleccionados = NORMA_26;
      break;
    case 'OBLIGACIONES QUE SE PAGAN O GARANTIZAN CON RECURSOS FEDERALES':
      datosSeleccionados = NORMA_27;
      break;
    case 'PRESUPUESTO DE EGRESOS APROBADO':
      datosSeleccionados = NORMA_28;
      break;
    case 'PROGRAMAS EN QUE CONCURRAN RECURSOS FEDERALES':
      datosSeleccionados = NORMA_29;
      break;
    case 'PROYECTO DE PRESUPUESTO DE EGRESOS.':
      datosSeleccionados = NORMA_30;
      break;
    case 'RELACION DE CUENTAS BANCARIAS PRODUCTIVAS ESPECIFICAS EN LAS QUE SE DEPOSITAN Y MINISTRAN REC FEDERALES.s':
      datosSeleccionados = NORMA_31;
      break;
    case 'LEY DE INGRESOS DEL ESTADO EJERCICIO FISCAL':
      datosSeleccionados = NORMA_32;
      break;

      datosSeleccionados = [];
  }

  return datosSeleccionados;
};



export const obtenerDatosSeleccionadosDisciplina = (selectedItemIndex) => {
  let datosSeleccionadosDisciplina = [];
  switch (selectedItemIndex) {
    case 'este es el titulo disciplina financiera':
      datosSeleccionadosDisciplina = disFinanciera;
      break;
    default:
      datosSeleccionadosDisciplina = [];
  }

  return datosSeleccionadosDisciplina;
};