import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from './components/Inicio/Inicio';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Transparencia from './components/Transparencia/Transparencia';
import Rubros from './components/Rubros/Rubros';
import DetalleRubro from './components/DetalleRubro/DetalleRubro';
import RubrosHistoricos from "./components/RubrosHistoricos/RubrosHistoricos";
import Agenda from "./components/Agenda/Agenda";
import ArmonizacionContable from "./components/ArmonizacionContable/ArmonizacionContable";
import Licitaciones from "./components/Licitaciones/Licitaciones";
import Servicios from "./components/Servicios/Servicios";
import Error404 from "./components/Error404/Error404";
import Nosotros from "./components/Nosotros/nosotros";
import { Armonizacion } from "./json/armonizacionContable";
import { Disciplina } from "./json/disciplinaFinanciera";


const Router = () => {
  return (
    <BrowserRouter>
      <Header/>
        <Routes>
          <Route exact path="/" element={ <Inicio /> } />
          <Route exact path="/transparencia" element={ <Transparencia/> } />
          <Route exact path="/transparencia/Rubros" element={ <Rubros/> } />
          <Route exact path="/transparencia/Rubros/DetalleRubro/:nombre" element={ <DetalleRubro/> } />
          <Route exact path="/transparencia/Rubros/RubrosHistoricos" element={ <RubrosHistoricos/> } />
          <Route exact path="/Agenda" element={ <Agenda/> } />
          <Route exact path="/Armonizacion" element={ <ArmonizacionContable data={Armonizacion} title="Armonizacion Contable" dataType="/Armonizacion" /> } />
          <Route exact path="/Disciplina" element={ <ArmonizacionContable data={Disciplina} title="Disciplina Financiera" dataType="/Disciplina" /> } />
          <Route exact path="/Licitaciones" element={ <Licitaciones /> } />
          <Route exact path="/Servicios" element={ <Servicios /> } />
          <Route exact path="*" element={<Error404 /> } />
          <Route exact path="/Nosotros" element={<Nosotros /> } />
          
         
        </Routes>
      <Footer/>
    </BrowserRouter> 
  )
}

export default Router