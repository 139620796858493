import React, { useEffect, useState } from 'react';
import Carousel from '../Carousel/Carousel';
import Modal from '../Modal/ModalConvocatoria';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import './inicio.css';
import { titular } from '../../json/titular';
import { Link } from 'react-router-dom';
import { carouselInicio } from '../../json/carousel';


const Inicio = () => {
  const [isModalOpen, setModalOpen] = useState(true);

   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Carousel bannerData={carouselInicio} search={true} name="banner-principal" />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='secretaria_logo' >
              <div className='secretaria_logo_img' style={{ backgroundImage: `url("https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Gobierno.svg")` }}>

              </div>
            </div>
          </div>
          <div className='secretaria_name'>
            <h1>SISTEMA INTEGRADO DE TRANSPORTE MASIVO DE HIDALGO</h1>
          </div>
          <div className='secretaria_funciones'>
            <h3 className='title'>¿Qué es?</h3>
            <hr className='hr-gob'/>
            <p>Se crea el Sistema Integrado de Transporte Masivo de Hidalgo como un Organismo Descentralizado de la Administración Pública del Estado, con personalidad jurídica y patrimonio propios, el cual está sectorizado a la Secretaría de Gobierno.
El Sistema Integrado de Transporte Masivo tendrá su domicilio en el Municipio de Pachuca de Soto, Hidalgo, y está facultado para establecer unidades administrativas en cualquiera de las regiones o Municipios del Estado.</p>
              
              <div className='secretaria-redes'>
              <h3 className='title'>Redes Sociales</h3>
                <a href='https://www.facebook.com/TuzobusOficial'><i class="fa-brands fa-facebook-f"></i></a>
                <a href='https://twitter.com/Tuzobus_Oficial'><i class="fa-brands fa-twitter"></i></a>
              </div>
          </div>
        </div>
        <UltimasNoticias/>
        <div className='row'>
          <div className='col-12'>
            <div className='notes_title'>
              <h3 className='title'>TITULAR DE LA DEPENDENCIA</h3>
              <hr className='hr-gob'/>
            </div>
            <div className='section_notes'>
              {
                titular.map((item, index) =>(
                    <>
                      <div key={ index } className='sec_photo'>
                       <div className='img_secretario' style={{ backgroundImage: `url("${ item.imagen }")` }}>
                       </div>
                      </div>
                      <div className='sec_container'>
                        <div className='sec_name'>
                          <h3>{ item.nombre }</h3>
                        </div>
                        <div className='sec_function'>
                          <h4>Función del Director General</h4>
                          <p>{ item.funcion }</p>
                          
                        </div>
                      </div>
                    </>
                ))
              }
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='notes_title'>
              <h3 className='title'>Programa Anual de Evaluación</h3>
              <hr className='hr-gob'/>
              <a href='https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/PROGRAMA_ANUAL_DE_EVALUACI%C3%93N_2023.pdf'>Primer Trimestre</a> <br></br>
              <a href='https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/Programa_Anual_de_Evaluación_2_trimestre_2023.pdf'>Segundo Trimestre</a>
            </div>
            
          </div>
        </div>



        <Modal isOpen={isModalOpen} onClose={closeModal}/>

        <div className='row'>
          <div className='col-12'>
            <div className='section_map'>
              <div className='map_name'>
                <h3 className='title'>UBICACIÓN</h3>
                <hr className='hr-gob'/>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14988.02005232343!2d-98.7524309!3d20.0920773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3a5a2da23e93be6e!2sSITMAH!5e0!3m2!1ses-419!2smx!4v1547578677387"
                      width="100%" 
                      height="300" 
                      frameborder="0" 
                      style={{border:0, marginBottom:50}} 
                      allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>   
      </div>
    </>
  )
}

export default Inicio