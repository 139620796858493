export const transparencia_item = [
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "/transparencia/Rubros"
    },
    {
        "transparencia_item_nombre" : "Rubros Historicos",
        "transparencia_item_url" : "/transparencia/Rubros/RubrosHistoricos"
    },
    {
        "transparencia_item_nombre" : "Armonización Contable",
        "transparencia_item_url" : "/Armonizacion"
    },
    {
        "transparencia_item_nombre" : "Licitaciones",
        "transparencia_item_url" : "/Licitaciones"
    }
];