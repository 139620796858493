export const rubros = [
		{
			
			"nombre": "1.-Normatividad",
			"icono": "Normatividad.png",
			"formatos": [
				{
					"nombre": "Marco Normativo",
					"xlsx": "f01",
					// "urlXlsx" : "https://www.google.com",
					"html": "f01",
					// "urlHtml" : "../htm/a69_f01SITMAH.htm"
				}
			],
			// "url" : "https://www.google.com"
		}, 
		{
			
			"nombre": "2.-Estructura",
			"icono":"Estructura.png",
			"formatos": [
				{
					"nombre": "Estructura Orgánica",
					"xlsx": "f02_a",
					"html": "f02_a"
				},
				{
					"nombre": "Organigrama",
					"xlsx": "f02_b",
					"html": "f02_b"
				}
			]
		},
		{
			
			"nombre": "3.-Facultades",
			"icono":"Facultades.png",
			"formatos": [
				{
					"nombre": "Facultades",
					"xlsx": "f03",
					"html": "f03"
				}
			]
		},
		{
			
			"nombre": "4.-Programas Operativos Anuales",
			"icono":"ProgramasOperativosAnuales.png",
			"formatos": [
				{
					"nombre": "Metas y Objetivos",
					"xlsx": "f04",
					"html": "f04"
				}
			]
			
		},
		{
			
			"nombre": "5.-Indicadores de Interés",
			"icono":"IndicadoresInteres.png",
			"formatos": [
				{
					"nombre": "Indicadores de Interés",
					"xlsx": "f5",
					"html": "f5"
				}
			]
			
		},
		{
			
			"nombre": "6.-Matriz de Indicadores",
			"icono":"MatrizIndicadores.png",
			"formatos": [
				{
					"nombre": "Matriz de Indicadores",
					"xlsx": "f6",
					"html": "f6"
				}
			]
			
		},
		{
			
			"nombre": "7.-Directorio",
			"icono":"Directorio.png",
			"formatos": [
				{
					"nombre": "Directorio",
					"xlsx": "f7",
					"html": "f7"
				}
			]
			
		},
		{
			
			"nombre": "8.-Sueldos",
			"icono":"Sueldos.png",
			"formatos": [
				{
					"nombre": "A",
					"xlsx_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos",
					"htm_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
				},
				{
					"nombre": "B",
					"xlsx_liga": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Sueldos.html",
					"htm_liga": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Sueldos.html"
				}
			]
			
		},
		{
			
			"nombre": "9.-Viáticos",
			"icono":"Viaticos.png",
			"formatos": [
				{
					"nombre": "Viáticos",
					"xlsx": "f9",
					"html": "f9"
				}
			]	
		},
		{
			
			"nombre": "10.-Plazas",
			"icono":"Plazas.png",
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/10.%20Plazas"
			/*"formatos": [
				{
					"nombre": "A",
					"xlsx_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/10.%20Plazas",
					"htm_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/10.%20Plazas"
				},
				{
					"nombre": "B",
					"xlsx_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/10.%20Plazas",
					"htm_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/10.%20Plazas"
				}
			]*/
		},
		{
			
			"nombre": "11.-Honorarios",
			"icono":"Honorarios.png",
			"formatos": [
				{
					"nombre": "Personal de Honorarios",
					"xlsx": "f11",
					"html": "f11"
				}
			]	
		},
		{
			"nombre": "12.-Declaraciones Públicas",
			"icono":"DeclaracionesPublicas.png",
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/12.%20Declaraciones%20P%C3%BAblicas"	
		},
		{
			"nombre": "13.-Datos de Contacto de la Unidad",
			"icono":"DatosContactoUnidad.png",
			"url": "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/13.%20Datos%20de%20Contacto%20de%20la%20Unidad"
		},
		{
			
			"nombre": "14.-Convocatorias",
			"icono":"Convocatorias.png",
			"formatos": [
				{
					"nombre": "Convocatorias a Concursos para Cargos Públicos",
					"xlsx": "f14",
					"html": "f14"
				}
			]
		},
		{
			
			"nombre": "15.-Programas Sociales",
			"icono":"ProgramasSociales.png",
			"formatos": [
				{
					"nombre": "Programas de Subsidio, Estimulo y Apoyo",
					"xlsx": "f15_a",
					"html": "f15_a"
				},
				{
					"nombre": "Padrón de beneficiarios",
					"xlsx": "f15_b",
					"html": "f15_b"
				}
			]
		},
		{
			
			"nombre": "16.-Relaciones Laborales",
			"icono":"RelacionesLaborales.png",
			"url" :"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/16.%20Relaciones%20Laborales",
			/*"formatos": [
				{
					"nombre": "A",
					"xlsx_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/16.%20Relaciones%20Laborales",
					"htm_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/16.%20Relaciones%20Laborales"
				},
				{
					"nombre": "B",
					"xlsx_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/16.%20Relaciones%20Laborales",
					"htm_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/16.%20Relaciones%20Laborales"
				}
			]*/	
		},
		{
			
			"nombre": "17.-Información Curricular",
			"icono":"InformacionCurricular.png",
			"formatos": [
				{
					"nombre": "Información Curricular",
					"xlsx": "f17",
					"html": "f17"
				}
			]
		},
		{
			
			"nombre": "18.-Sanciones Administrativas",
			"icono":"SancionesAdministrativas.png",
			"url": "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/18.%20Sanciones%20Administrativas"	
		},
		{
			
			"nombre": "19.-Servicios",
			"icono":"Servicios.png",
			"formatos": [
				{
					"nombre": "Servicios",
					"xlsx": "f19",
					"html": "f19"
				}
			]
			
		},
		{
			
			"nombre": "20.-Trámites",
			"icono":"Tramites.png",
			"formatos": [
				{
					"nombre": "Trámites",
					"xlsx": "f20",
					"html": "f20"
				}
			]
			
		},
		{
			
			"nombre": "21.-Presupuesto e Informes Trimestrales",
			"icono":"PresupuestoInformesTrimestrales.png",
			"formatos": [
				{
					"nombre": "Presupuesto Anual",
					"xlsx": "f21_a",
					"html": "f21_a"
				},
				{
					"nombre": "Gasto Trimestral",
					"xlsx": "f21_b",
					"html": "f21_b"
				},
				{
					"nombre": "Cuenta Pública",
					"xlsx": "f21_c",
					"html": "f21_c"
				}
			]
			
		},
		{
			
			"nombre": "22.-Deuda Pública",
			"icono":"DeudaPublica.png",
			"url": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Deuda_Publica.html"
		},
		{
			
			"nombre": "23.-Comunicación Social y Publicidad",
			"icono":"ComunicacionSocialPublicidad.png",
			"formatos": [
				{
					"nombre": "Programa Anual de Comunicación Social",
					"xlsx": "f23_a",
					"html": "f23_a"
				},
				{
					"nombre": "Erogación de Recursos de Comunicación Social",
					"xlsx": "f23_b",
					"html": "f23_b"
				},
				{
					"nombre": "Tiempos Oficiales",
					"xlsx": "f23_c",
					"html": "f23_c",
					"xlsx_liga": "https://radioytelevision.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Comunicaci%C3%B3n%20Social%20y%20Publicidad",
					"htm_liga": "https://radioytelevision.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Comunicaci%C3%B3n%20Social%20y%20Publicidad"
				},
				{
					"nombre": "Información Relacionada a Tiempos Oficiales",
					"xlsx": "f23_d",
					"html": "f23_d",
					"xlsx_liga": "https://radioytelevision.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Comunicaci%C3%B3n%20Social%20y%20Publicidad",
					"htm_liga": "https://radioytelevision.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Comunicaci%C3%B3n%20Social%20y%20Publicidad"
				},

			]
		},
		{
			
			"nombre": "24.-Resultados de Auditorías",
			"icono":"ResultadosAuditorias.png",
			"url": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Resultados_de_Auditorias.html"
		},
		{
			
			"nombre": "25.-Dictamenes de Estados Financieros",
			"icono":"DictamenesFinancieros.png",
			"formatos": [
				{
					"nombre": "Estados Financieros",
					"xlsx": "f25",
					"html": "f25"
				}
			]
			
		},
		{
			
			"nombre": "26.-Asignación de Recursos Públicos a Personas",
			"icono":"AsignacionRecursosPublicosPersonas.png",
			"url": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/26.%20Asignaci%C3%B3n%20de%20Recursos%20P%C3%BAblicos%20a%20Personas"
			
		},
		{
			
			"nombre": "27.-Concesiones",
			"icono":"Concesiones.png",
			"formatos": [
				{
					"nombre": "Concesiones, Contratos, Convenios, Permisos, Licencias y Autorizaciones",
					"xlsx": "f27",
					"html": "f27"
				}
			]
			
		},
		{
			
			"nombre": "28.-Licitaciones Públicas",
			"icono":"LicitacionesPublicas.png",
			"formatos": [
				{
					"nombre": "Procedimientos de Licitación Pública e Invitación a Cuando Menos Tres Personas",
					"xlsx": "f28",
					"html": "f28"
				}/*,
				{
					"nombre": "Procedimientos de Adjudicación Directa",
					"xlsx": "f28_b",
					"html": "f28_b"
				}*/
			]
			
		},
		{
			
			"nombre": "29.-Informes",
			"icono":"Informes.png",
			"formatos": [
				{
					"nombre": "Informes",
					"xlsx": "f29",
					"html": "f29"
				}
			]
			
		},
		{
			
			"nombre": "30.-Estadísticas Generadas",
			"icono":"EstadisticasGenerales.png",
			"formatos": [
				{
					"nombre": "Estadísticas",
					"xlsx": "f30",
					"html": "f30"
				}
			]
			
		},
		{
			
			"nombre": "31.-Informe de Avances y Documentos Financiero",
			"icono":"InformeAvancesDocumentosFinancieros.png",
			"formatos": [
				{
					"nombre": "Informe de Estados Financieros",
					"xlsx": "f31_a",
					"html": "f31_a"
				},
				{
					"nombre": "Informe Contable, Presupuestal y Programático",
					"xlsx": "f31_b",
					"html": "f31_b"
				}
			]
			
		},
		{
			
			"nombre": "32.-Padrón de Proveedores y Contratistas",
			"icono":"PadronProveedoresContratistas.png",
			"url": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Padron_de_Proveedores_y_Contratistas.html"
			
		},
		{
			
			"nombre": "33.-Convenios con Sectores Social y Privado",
			"icono":"ConveniosSectoresSocialPrivado.png",
			"formatos": [
				{
					"nombre": "Convenios de Coordinación y Concertación",
					"xlsx": "f33",
					"html": "f33"
				}
			]
			
		},
		{
			
			"nombre": "34.-Inventario de Bienes",
			"icono":"InventarioBienes.png",
			"formatos": [
				{
					"nombre": "Inventario de Bienes Muebles",
					"xlsx": "f34_a",
					"html": "f34_a"
				},
				{
					"nombre": "Alta de Bienes Muebles",
					"xlsx": "f34_b",
					"html": "f34_b"
				},
				{
					"nombre": "Bajas de Bienes Muebles",
					"xlsx": "f34_c",
					"html": "f34_c"
				},
				{
					"nombre": "Inventario de Bienes Inmuebles",
					"xlsx": "f34_d",
					"html": "f34_d"
				},
				{
					"nombre": "Alta de Bienes Inmuebles",
					"xlsx": "f34_e",
					"html": "f34_e"
				},
				{
					"nombre": "Bajas de Bienes Inmuebles",
					"xlsx": "f34_f",
					"html": "f34_f"
				},
				{
					"nombre": "Donaciones de Bienes Muebles e Inmuebles",
					"xlsx": "f34_g",
					"html": "f34_g"
				}

			]
			
		},
		{
			
			"nombre": "35.-Recomendaciones y Atención en Materia de Derechos Humanos",
			"icono":"RecomendacionesAtenciónMateriaDerechosHumanos.png",
			"formatos": [
				{
					"nombre": "Recomendaciones de Derechos Humanos Emitidas por la CNDH",
					"xlsx": "f35_a",
					"html": "f35_a"
				},
				{
					"nombre": "Recomendaciones de Derechos Humanos Casos Especiales",
					"xlsx": "f35_b",
					"html": "f35_b"
				},
				{
					"nombre": "Las Recomendaciones de Derechos Humanos Org. Int. CNDH",
					"xlsx": "f35_c",
					"html": "f35_c"
				},
			]
			
		},
		{
			
			"nombre": "36.-Resoluciones y Laudos de Juicios",
			"icono":"ResolucionesLaudosJuicios.png",
			"url": "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/36.%20Resoluciones%20y%20Laudos%20de%20Juicios"
			
		},
		{
			
			"nombre": "37.-Mecanismo de Participación Ciudadana",
			"icono":"MecanismosParticipacionCiudadana.png",
			"formatos": [
				{
					"nombre": "Mecanismos de Participación Ciudadana",
					"xlsx": "f37_a",
					"html": "f37_a"
				},
				{
					"nombre": "Resultados de los Mecanismos de Participación Ciudadana",
					"xlsx": "f37_b",
					"html": "f37_b"
				}
			]
			
		},
		{
			
			"nombre": "38.-Programas Ofrecidos",
			"icono":"ProgramasOfrecidos.png",
			"formatos": [
				{
					"nombre": "A",
					"xlsx": "f38_a",
					"html": "f38_a"
				},
				{
					"nombre": "B",
					"xlsx": "f38_b",
					"html": "f38_b"
				}
			]
			
		},
		{
			
			"nombre": "39.-Actas y Resoluciones del Comité de Transparencia",
			"icono":"ActasResolucionesComiteTransparencia.png",
			"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/39.%20Actas%20y%20Resoluciones%20del%20Comit%C3%A9%20de%20Transparencia",
			/*"formatos": [
				{
					"nombre": "A",
					"xlsx_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Actas_y_Resoluciones_del_Comite_de_Transparencia.html",
					"htm_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Actas_y_Resoluciones_del_Comite_de_Transparencia.html"
				},
				{
					"nombre": "B",
					"xlsx_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Actas_y_Resoluciones_del_Comite_de_Transparencia.html",
					"htm_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Actas_y_Resoluciones_del_Comite_de_Transparencia.html"
				},
				{
					"nombre": "C",
					"xlsx_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Actas_y_Resoluciones_del_Comite_de_Transparencia.html",
					"htm_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Actas_y_Resoluciones_del_Comite_de_Transparencia.html"
				},{
					"nombre": "D",
					"xlsx_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Actas_y_Resoluciones_del_Comite_de_Transparencia.html",
					"htm_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Actas_y_Resoluciones_del_Comite_de_Transparencia.html"
				}
			]*/
			
		},
		{
			
			"nombre": "40.-Evaluacion y Encuesta a Programas Financiados con Recursos Públicos",
			"icono":"EvaluacionencuestasProgramas.png", 
			"url":"https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Evaluacion_y_Encuestas_a_Programas_Financiados_con_Recursos_Publicos.html",
			/*"formatos": [
				{
					"nombre": "A",
					"xlsx_liga": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Evaluacion_y_Encuestas_a_Programas_Financiados_con_Recursos_Publicos.html",
					"htm_liga": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Evaluacion_y_Encuestas_a_Programas_Financiados_con_Recursos_Publicos.html"
				},
				{
					"nombre": "B",
					"xlsx_liga": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Evaluacion_y_Encuestas_a_Programas_Financiados_con_Recursos_Publicos.html",
					"htm_liga": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Evaluacion_y_Encuestas_a_Programas_Financiados_con_Recursos_Publicos.html"
				}
			]*/
		},
		{
			
			"nombre": "41.-Estudios Pagados con Recursos Públcos",
			"icono":"EstudiosPagadosRecursosPublicos.png",
			"formatos": [
				{
					"nombre": "Estudios Pagados con Recursos Públicos ",
					"xlsx": "f41",
					"html": "f41"
				}
			]
			
		},
		{
			
			"nombre": "42.-Listados Jubilados y Pensionados",
			"icono":"ListadoJubiladosPencionados.png",
			"url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados",
			/*"formatos": [
				{
					"nombre": "A",
					"xlsx_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados",
					"htm_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados"
				},
				{
					"nombre": "B",
					"xlsx_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados",
					"htm_liga": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados"
				}
			]*/
		},
		{
			
			"nombre": "43.-Ingresos Recibidos por Cualquier Concepto",
			"icono":"IngresosRecibidosCualquierConcepto.png",
			"formatos": [
				{
					"nombre": "Ingresos Recibidos",
					"xlsx": "f43_a",
					"html": "f43_a"
				},
				{
					"nombre": "Responsables de Recibir los Ingresos",
					"xlsx": "f43_b",
					"html": "f43_b"
				}
			]	
			
		},
		{
			
			"nombre": "44.-Donaciones Hechas a Terceros",
			"icono":"DonacionesHechasTerceros.png",
			"formatos": [
				{
					"nombre": "Donaciones en dinero y en especie realizadas",
					"xlsx": "f44",
					"html": "f44"
				}
			]	
			
		},
		{
			
			"nombre": "45.-Catálogo Documental y Guia de Archivos",
			"icono":"Viaticos.png",
			"formatos": [
				{
					"nombre": "Inventarios documentales",
					"xlsx": "f45_a",
					"html": "f45_a"
				},{
					"nombre": "Índice de expedientes clasificados",
					"xlsx_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Catalogo_Documental_y_Guia_de_Archivos.html",
					"htm_liga": "https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Catalogo_Documental_y_Guia_de_Archivos.html"
				},
				{
					"nombre": "Instrumentos Archivisticos",
					"xlsx": "f45_c",
					"html": "f45_c"
				}
			]
			
		},
		{
			
			"nombre": "46.-Actas de Sesiones, Opiniones y Recomendaciones de Consejos Consultivos",
			"icono":"CatalogoDocumentalGuiaArchivos.png",
			"url":"https://s-turismo.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/46.Actas%20de%20Sesiones,%20Opiniones%20y%20Recomendaciones%20de%20Consejos%20Consultivos",
			/*"formatos": [
				{
					"nombre": "A",
					"xlsx_liga": "https://s-turismo.hidalgo.gob.mx/transparencia/48rubros/Actas_de_Sesiones_Opiniones_y_Recomendaciones_de_Consejos_Consultivos.html",
					"htm_liga": "https://s-turismo.hidalgo.gob.mx/transparencia/48rubros/Actas_de_Sesiones_Opiniones_y_Recomendaciones_de_Consejos_Consultivos.html"
				},
				{
					"nombre": "B",
					"xlsx_liga": "https://s-turismo.hidalgo.gob.mx/transparencia/48rubros/Actas_de_Sesiones_Opiniones_y_Recomendaciones_de_Consejos_Consultivos.html",
					"htm_liga": "https://s-turismo.hidalgo.gob.mx/transparencia/48rubros/Actas_de_Sesiones_Opiniones_y_Recomendaciones_de_Consejos_Consultivos.html"
				}
			]*/
		},
		{
			
			"nombre": "47.-Solicitud de Intervecion de  Comunicaciones",
			"icono":"SolicitudIntervencionComunicaciones.png",
			"url":"https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones",
			/*"formatos": [
				{
					"nombre": "A",
					"xlsx_liga": "https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones",
					"htm_liga": "https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones"
				},
				{
					"nombre": "B",
					"xlsx_liga": "https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones",
					"htm_liga": "https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones"
				},
				{
					"nombre": "C",
					"xlsx_liga": "https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones",
					"htm_liga": "https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones"
				}
			]*/
		},
		{
			
			"nombre": "48.-Información de Utilidad o Relevante",
			"icono":"InformacionUtilidadRelevante.png",
			"formatos": [
				{
					"nombre": "Interés Público",
					"xlsx": "f48_a",
					"html": "f48_a"
				},
				{
					"nombre": "Preguntas Frecuentes",
					"xlsx": "f48_b",
					"html": "f48_b"
				},
				{
					"nombre": "Transparencia Proactiva",
					"xlsx": "f48_c",
					"html": "f48_c"
				}
			]

			
		},
]