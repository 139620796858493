export const disFinanciera = [
    {
        "anio" : "2040",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }
];